import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Link,
  ListSubheader,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import RichTextEditor from "common/RichTextEditor";
import { ScreenHeader } from "common/ScreenHeader";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import { TextField as FmuiTextField, Checkbox as FmuiCheckbox } from "formik-mui";
import { DateTime } from "luxon";
import {
  RegistrationCommitteeItem,
  RegistrationCommitteeItemCategory,
  RegistrationCommitteeItemInput,
  RegistrationCommitteeItemType
} from "registration-committee-items";
import React, { useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import { formatDate } from "util/formats";
import {
  ApproveRegistrationCommitteeItemForMeetingMutation,
  DeleteFileFromRegistrationCommitteeItemMutation,
  FetchRegistrationCommitteeItemTypesWithCategories,
  SaveRegistrationCommitteeItemMutation,
  SendRegistrationCommitteeItemForApprovalMutation
} from "./queries";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "notifications";
import { AvailableMeetingsQuery, CommitteeMeeting, FetchUnassignedRegistrationCommitteeItemsQuery } from "committee-meetings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAxios } from "auth/SecureAxios";
import { useAppConfig } from "util/AppConfig";
import { AxiosResponse } from "axios";
import { validateFileNameForSharePoint } from "practice-reviews/AttachedDocumentActions";
import { makeStyles } from "makeStyles";
import { Permissions, useCurrentUser } from "users";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { MergeFieldType } from "common/HtmlMergeFields/models";
import { AttachedDocument } from "practice-reviews";
import { DocType } from "common/DocType";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";
import { MicrosoftWordLink } from "common/MicrosoftWordLink";
import { getRouteForRegistrationCommitteeItemId, RegistrationCommitteeItemTabs } from "./RegistrationCommitteeItemScreen";

const useStyles = makeStyles()((theme) => ({
  independentHeight: {
    height: "fit-content"
  },
  fileUploadContainer: {
    width: "100%",
    position: "relative",
    paddingTop: "50%"
  },
  fileUploadBackground: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.highlight
  },
  fileUploadTextContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  fileUploadTable: {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2)
  },
  fileUploadTableCell: {
    margin: "auto 0"
  },
  fileUploadTableHeaderCell: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    height: "100%"
  }
}));

interface Props {
  item?: RegistrationCommitteeItem;
}

interface FormValues {
  entityNumber: string | null;
  dueDate: DateTime | null;
  registeredName: string | null;
  contactName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  mailingAddress1: string | null;
  mailingAddress2: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  notes: string | null;
  itemTypeId: number | null;
  committeeMeetingId: number | null;
  documentsIncludedInBinder: boolean[];
  background: string | null;
  issue: string | null;
  committeeToDecide: string | null;
  decisionOptions: string | null;
}

const noneValue = -1;
const validationSchema = Yup.object({
  entityNumber: Yup.number().nullable().required("Required."),
  dueDate: Yup.date().nullable().required("Required."),
  registeredName: Yup.string().nullable().required("Required."),
  contactName: Yup.string().nullable().required("Required."),
  emailAddress: Yup.string().nullable().email("Must be a valid email address.").required("Required."),
  phoneNumber: Yup.string().nullable().required("Required."),
  mailingAddress1: Yup.string().nullable().required("Required."),
  city: Yup.string().nullable().required("Required."),
  province: Yup.string().nullable().required("Required."),
  postalCode: Yup.string().nullable().required("Required."),
  itemTypeId: Yup.number().nullable().not([noneValue], "Required.").required("Required.")
});

const EditRegistrationCommitteeItemTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const { item } = props;
  const history = useHistory();
  const notifications = useNotifications();
  const { secureAxios } = useAxios();
  const apolloClient = useApolloClient();
  const appConfig = useAppConfig();
  const user = useCurrentUser();

  const { unsavedChanges, changesSaved } = useUnsavedChanges();

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const backgroundContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const issueContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const committeeToDecideContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const decisionOptionsContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });

  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [fileToRemove, setFileToRemove] = useState<AttachedDocument | null>(null);

  const [deleteFile] = useMutation<
    { registrationCommitteeItem: { deleteFile: RegistrationCommitteeItem } },
    { itemId: number; attachedDocumentId: number }
  >(DeleteFileFromRegistrationCommitteeItemMutation);

  const typesQuery = useQuery<{ registrationCommitteeItemTypes: RegistrationCommitteeItemType[] }>(
    FetchRegistrationCommitteeItemTypesWithCategories,
    { variables: { includeInactive: false } }
  );
  const types = useMemo(() => typesQuery.data?.registrationCommitteeItemTypes ?? [], [typesQuery.data?.registrationCommitteeItemTypes]);
  const allCategories = useMemo(
    () =>
      Object.values(
        types.reduce(
          // Invert the structure with types getting moved into childTypes of their parent categories
          (obj, type) => ({
            ...obj,
            [type.category.id]: {
              ...(type.category.id in obj ? obj[type.category.id] : type.category),
              childTypes: [...(type.category.id in obj ? obj[type.category.id].childTypes : []), type]
            }
          }),
          {} as { [id: number]: RegistrationCommitteeItemCategory }
        )
      ),
    [types]
  );

  const meetingsQuery = useQuery<{ availableMeetings: CommitteeMeeting[] }>(AvailableMeetingsQuery);
  const availableMeetings = meetingsQuery.data?.availableMeetings.filter((m) => m.isRegistrationCommittee) ?? [];
  const additionalDocuments = item?.attachedDocuments.filter((ad) => ad.type === DocType.AdditionalFile) ?? [];
  const initialValues: FormValues = {
    entityNumber: item?.entityNumber.toString() ?? "",
    dueDate: item ? DateTime.fromISO(item.dueDate) : null,
    registeredName: item?.registeredName ?? "",
    contactName: item?.contactName ?? "",
    emailAddress: item?.emailAddress ?? "",
    phoneNumber: item?.phoneNumber ?? "",
    mailingAddress1: item?.mailingAddress1 ?? "",
    mailingAddress2: item?.mailingAddress2 ?? "",
    city: item?.city ?? "",
    province: item?.province ?? "",
    postalCode: item?.postalCode ?? "",
    notes: item?.notes ?? null,
    itemTypeId: item?.itemType.id ?? noneValue,
    committeeMeetingId: item?.committeeMeeting?.id ?? noneValue,
    documentsIncludedInBinder: additionalDocuments.map((ad) => !ad.isExcludedFromRegComBinder) ?? [],
    background: item?.background ?? null,
    issue: item?.issue ?? null,
    committeeToDecide: item?.committeeToDecide ?? null,
    decisionOptions: item?.decisionOptions ?? null
  };

  const [sendForApprovalMutate, sendForApprovalMutation] = useMutation<
    { registrationCommitteeItem: { sendForApproval: RegistrationCommitteeItem } },
    { itemId: number }
  >(SendRegistrationCommitteeItemForApprovalMutation);
  const [approveForMeetingMutate, approveForMeetingMutation] = useMutation<
    { registrationCommitteeItem: { approveForMeeting: RegistrationCommitteeItem } },
    { itemId: number }
  >(ApproveRegistrationCommitteeItemForMeetingMutation);
  const approveMutationLoading = sendForApprovalMutation.loading || approveForMeetingMutation.loading;

  const approveOrSendForApproval = async () => {
    if (!item!.isReleasedForApproval) {
      await sendForApprovalMutate({ variables: { itemId: item!.id } });
      if (!sendForApprovalMutation.error) {
        notifications.success("Item sent for approval.");
      }
    } else if (!item!.isApprovedForMeeting && user.userHasPermission(Permissions.AdminTasksPprp)) {
      await approveForMeetingMutate({ variables: { itemId: item!.id } });
      if (!approveForMeetingMutation.error) {
        notifications.success("Item approved for meeting.");
      }
    }
  };

  const dataSheet = item?.attachedDocuments.filter((ad) => ad.type === DocType.RegistrationCommitteeItemDataSheet)[0];
  const finalReportPackage = item?.attachedDocuments.filter((ad) => ad.type === DocType.RegistrationCommittteeItemFinalReportPackage)[0];

  const filesSelected = (files: File[]) => {
    let validationErrorExists = false;

    files.forEach((file) => {
      if (additionalDocuments.some((ad) => ad.fileName === file.name)) {
        notifications.error(`The agenda item already has a file named ${file.name}.`);
        validationErrorExists = true;
      }

      const invalidSharePointFileNameErrorMessage = validateFileNameForSharePoint(file.name);
      if (invalidSharePointFileNameErrorMessage !== null) {
        notifications.error(invalidSharePointFileNameErrorMessage);
        validationErrorExists = true;
      }

      if (file.size > 26214400) {
        notifications.error(`${file.name} is too large (max file size 25MB).`);
        validationErrorExists = true;
      }
    });

    if (validationErrorExists) {
      return;
    }

    setFilesToUpload(filesToUpload.concat(Array.from(files)));
    unsavedChanges();
  };

  const removeFile = (file: File) => {
    setFilesToUpload(filesToUpload.filter((f) => f !== file));
    unsavedChanges();
  };

  const uploadFiles = async (files: File[], itemId: number) => {
    const formData = new FormData();
    formData.append("RegistrationCommitteeItemId", itemId.toString());
    files.forEach((file) => {
      formData.append("Documents", file);
    });

    let postResult: AxiosResponse<RegistrationCommitteeItem>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/registration-committee-item-document/upload`;
    try {
      postResult = await secureAxios.post(postRequestEndpoint, formData);
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await secureAxios.post(postRequestEndpoint, formData);
      }
    } catch (e: any) {
      notifications.serverError(e.message);
      return;
    }

    if (postResult?.status !== 200) {
      notifications.serverError(new Error(postResult?.statusText));
    }

    const updatedItem = postResult.data;
    updateRegistrationCommitteeItemInCache(updatedItem);
  };

  const updateRegistrationCommitteeItemInCache = (updatedItem: RegistrationCommitteeItem) => {
    const cacheId = `RegistrationCommitteeItem:${updatedItem.id}`;

    apolloClient.cache.modify({
      id: cacheId,
      fields: {
        attachedDocuments() {
          return updatedItem.attachedDocuments;
        }
      }
    });
  };

  const [saveMutate] = useMutation<
    { registrationCommitteeItem: { save: RegistrationCommitteeItem } },
    { item: RegistrationCommitteeItemInput }
  >(SaveRegistrationCommitteeItemMutation, {
    refetchQueries: [{ query: FetchUnassignedRegistrationCommitteeItemsQuery }]
  });

  const save = async (values: FormValues) => {
    const itemInput: RegistrationCommitteeItemInput = {
      id: item?.id,
      entityNumber: Number(values.entityNumber!),
      registeredName: values.registeredName!,
      contactName: values.contactName!,
      emailAddress: values.emailAddress!,
      phoneNumber: values.phoneNumber!,
      mailingAddress1: values.mailingAddress1!,
      mailingAddress2: values.mailingAddress2!,
      city: values.city!,
      province: values.province!,
      postalCode: values.postalCode!,
      dueDate: values.dueDate!.toISO(),
      notes: notesContentRetriever.current.getContentAsHtml(),
      itemTypeId: values.itemTypeId!,
      committeeMeetingId: values.committeeMeetingId === noneValue ? null : values.committeeMeetingId,
      documentsIncludedInBinder:
        additionalDocuments.map((ad, index) => ({
          id: ad.id,
          isExcludedFromRegComBinder: !values.documentsIncludedInBinder[index]
        })) ?? [],
      background: backgroundContentRetriever.current.getContentAsHtml(),
      issue: issueContentRetriever.current.getContentAsHtml(),
      committeeToDecide: committeeToDecideContentRetriever.current.getContentAsHtml(),
      decisionOptions: decisionOptionsContentRetriever.current.getContentAsHtml()
    };
    var result = await saveMutate({
      variables: {
        item: itemInput
      }
    });
    if (result.data?.registrationCommitteeItem.save) {
      if (filesToUpload.length > 0) {
        await uploadFiles(filesToUpload, result.data.registrationCommitteeItem.save.id);
        setFilesToUpload([]);
      }
      changesSaved();
      notifications.success("Item saved.");
      if (!item) {
        history.push(
          getRouteForRegistrationCommitteeItemId(result.data.registrationCommitteeItem.save.id, RegistrationCommitteeItemTabs.Overview)
        );
      }
    }
  };

  const meetingHasPassed =
    item && item.committeeMeeting !== null && !availableMeetings.some((meeting) => meeting.id === item.committeeMeeting!.id);

  const actionButtonIsSendForApproval = item && !item.isReleasedForApproval;
  const actionButtonIsApproveForMeeting = !actionButtonIsSendForApproval && item && !item.isApprovedForMeeting;
  const actionButtonDisabledReason =
    item?.committeeMeeting === null
      ? "Item must be assigned to a meeting first."
      : actionButtonIsSendForApproval && !user.userHasPermission(Permissions.RegComItemSendForApproval)
      ? "You do not have permission to send items for approval."
      : actionButtonIsApproveForMeeting && !user.userHasPermission(Permissions.RegComItemApproveForMeeting)
      ? "You do not have permission to approve items for a meeting."
      : "";

  return (
    <>
      <Helmet>
        <title>{item ? "Edit" : "Create"} Registration Committee Item - PRS Online</title>
      </Helmet>

      <ScreenHeader title={`${item ? "Edit" : "Create"} Registration Committee Item`} />
      <Stack spacing={3}>
        <Paper sx={{ p: 3, flexGrow: 1 }}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={save} validationSchema={validationSchema}>
            {(formikProps) => (
              <FormikForm onChange={() => unsavedChanges()}>
                <Typography variant="h1" gutterBottom mb={3}>
                  Registration Committee Item
                </Typography>
                <Grid container columnSpacing={12}>
                  <Grid container item xs={6} columnSpacing={2} rowSpacing={1} className={classes.independentHeight}>
                    <Grid item xs={6}>
                      <ReadOnlyWrapper
                        title="Item type cannot be changed once it has been assigned to a meeting."
                        readOnly={!!item && item.committeeMeeting !== null}>
                        <FormikField
                          component={FmuiTextField}
                          select
                          name="itemTypeId"
                          label="Type"
                          fullWidth
                          required
                          disabled={formikProps.isSubmitting || (item && item.committeeMeeting !== null)}
                          SelectProps={{
                            renderValue: (id: number) => {
                              const itemType = types.find((t) => t.id === id);
                              return (
                                <>
                                  <Typography variant="subtitle2" color="GrayText">
                                    {itemType?.category.name}
                                  </Typography>
                                  <Typography>{itemType?.name}</Typography>
                                </>
                              );
                            }
                          }}
                          onChange={(e: SelectChangeEvent<number>) => {
                            formikProps.setFieldValue("itemTypeId", e.target.value);
                            formikProps.setFieldTouched("itemTypeId", true);
                            unsavedChanges();
                          }}>
                          {allCategories.map((category) =>
                            [<ListSubheader>{category.name}</ListSubheader>].concat(
                              category.childTypes.map((itemType) => (
                                <MenuItem value={itemType.id} key={itemType.id} sx={{ pl: 3 }}>
                                  {itemType.name}
                                </MenuItem>
                              ))
                            )
                          )}
                        </FormikField>
                      </ReadOnlyWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      {item && (
                        <ReadOnlyWrapper title="Assigned meeting has already passed." readOnly={!!meetingHasPassed}>
                          <FormikField
                            component={FmuiTextField}
                            select
                            name="committeeMeetingId"
                            label="Committee Meeting"
                            fullWidth
                            disabled={
                              formikProps.isSubmitting ||
                              (item?.committeeMeeting !== null &&
                                !availableMeetings.some((meeting) => meeting.id === item!.committeeMeeting!.id))
                            }
                            onChange={(e: SelectChangeEvent<number>) => {
                              formikProps.setFieldValue("committeeMeetingId", e.target.value);
                              formikProps.setFieldTouched("committeeMeetingId", true);
                              unsavedChanges();
                            }}>
                            {availableMeetings.map((meeting) => (
                              <MenuItem value={meeting.id} key={meeting.id}>
                                {meeting.name}
                              </MenuItem>
                            ))}
                            {meetingHasPassed && (
                              <MenuItem value={item!.committeeMeeting!.id} key={item!.committeeMeeting!.id}>
                                {item!.committeeMeeting!.name}
                              </MenuItem>
                            )}
                          </FormikField>
                        </ReadOnlyWrapper>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormikField
                        component={FmuiTextField}
                        name="entityNumber"
                        label="Entity Number"
                        fullWidth
                        required
                        inputProps={{ pattern: "[0-9]+" }}
                      />
                    </Grid>
                    {item && (
                      <Grid item xs={2} sx={{ pt: 1.5 }}>
                        <StackedStaticDataDisplay label="Created On" value={formatDate(item.createdDate)} />
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <FormikField
                        component={PrsDatePickerField}
                        name="dueDate"
                        label="Due Date"
                        fullWidth
                        required
                        minDate={DateTime.local()}
                        onBlur={() => {
                          formikProps.setFieldTouched("dueDate", true);
                        }}
                        onChange={(newValue: DateTime) => {
                          formikProps.setFieldValue("dueDate", newValue);
                          unsavedChanges();
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField component={FmuiTextField} name="registeredName" label="Registered Name" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField component={FmuiTextField} name="contactName" label="Contact Name" fullWidth required />
                    </Grid>
                    <Grid item xs={9}>
                      <FormikField component={FmuiTextField} name="emailAddress" label="Email Address" fullWidth required />
                    </Grid>
                    <Grid item xs={3}>
                      <FormikField
                        component={FmuiTextField}
                        name="phoneNumber"
                        label="Phone Number"
                        fullWidth
                        required
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <FormikField component={FmuiTextField} name="mailingAddress1" label="Mailing Address 1" fullWidth required />
                    </Grid>
                    <Grid item xs={3}>
                      <FormikField component={FmuiTextField} name="postalCode" label="Postal Code" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField component={FmuiTextField} name="mailingAddress2" label="Mailing Address 2" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikField component={FmuiTextField} name="city" label="City" fullWidth required />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikField component={FmuiTextField} name="province" label="Province" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        label="Notes"
                        minHeight="6em"
                        html={formikProps.values.notes}
                        passContentRetriever={(getContentAsHtml) => {
                          notesContentRetriever.current = { getContentAsHtml };
                        }}
                        reportUnsavedChanges
                        hideToolbar
                        readOnly={formikProps.isSubmitting}
                      />
                    </Grid>
                    {item?.committeeMeeting &&
                      (item.isApprovedForMeeting ? (
                        <Grid item xs={12} mt={1}>
                          <Typography variant="h4">
                            <em>
                              Open the Data Sheet document to edit the Background, Issue, Committee to Decide, and Decision Options
                              directly.
                            </em>
                          </Typography>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <RichTextEditor
                              label="Background"
                              minHeight="6em"
                              showMergeFields
                              mergeFieldType={MergeFieldType.RegComItem}
                              templateMarkup
                              html={formikProps.values.background}
                              passContentRetriever={(getContentAsHtml) => {
                                backgroundContentRetriever.current = { getContentAsHtml };
                              }}
                              reportUnsavedChanges
                              readOnly={formikProps.isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RichTextEditor
                              label="Issue"
                              minHeight="6em"
                              showMergeFields
                              mergeFieldType={MergeFieldType.RegComItem}
                              templateMarkup
                              html={formikProps.values.issue}
                              passContentRetriever={(getContentAsHtml) => {
                                issueContentRetriever.current = { getContentAsHtml };
                              }}
                              reportUnsavedChanges
                              readOnly={formikProps.isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RichTextEditor
                              label="Committee To Decide"
                              minHeight="6em"
                              showMergeFields
                              mergeFieldType={MergeFieldType.RegComItem}
                              templateMarkup
                              html={formikProps.values.committeeToDecide}
                              passContentRetriever={(getContentAsHtml) => {
                                committeeToDecideContentRetriever.current = { getContentAsHtml };
                              }}
                              reportUnsavedChanges
                              readOnly={formikProps.isSubmitting}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RichTextEditor
                              label="Decision Options"
                              minHeight="6em"
                              showMergeFields
                              mergeFieldType={MergeFieldType.RegComItem}
                              templateMarkup
                              html={formikProps.values.decisionOptions}
                              passContentRetriever={(getContentAsHtml) => {
                                decisionOptionsContentRetriever.current = { getContentAsHtml };
                              }}
                              reportUnsavedChanges
                              readOnly={formikProps.isSubmitting}
                            />
                          </Grid>
                        </>
                      ))}
                  </Grid>
                  <Grid container item xs={6} className={classes.independentHeight} rowGap={3}>
                    <Grid container className={classes.fileUploadTable} rowGap={3}>
                      <Grid
                        item
                        xs={12}
                        className={classes.fileUploadContainer}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          if (!formikProps.isSubmitting) {
                            if (e.dataTransfer.items) {
                              filesSelected(
                                Array.from(e.dataTransfer.items)
                                  .filter((i) => i.kind === "file")
                                  .map((i) => i.getAsFile() as File)
                              );
                            } else {
                              filesSelected(Array.from(e.dataTransfer.files));
                            }
                          }
                          e.preventDefault();
                        }}>
                        <div className={classes.fileUploadBackground}>
                          <div className={classes.fileUploadTextContainer}>
                            <Stack direction="row" spacing={0.5}>
                              <Link
                                component="label"
                                variant="body1"
                                color={formikProps.isSubmitting ? "disabled" : "primary"}
                                underline={formikProps.isSubmitting ? "none" : "hover"}
                                sx={{ cursor: formikProps.isSubmitting ? "default" : "pointer" }}>
                                Click to Upload
                                <input
                                  type="file"
                                  hidden
                                  accept="*.*"
                                  multiple
                                  disabled={formikProps.isSubmitting}
                                  onChange={(e) => filesSelected(Array.from(e.target.files!))}
                                />
                              </Link>
                              <Typography>or Drag and Drop</Typography>
                            </Stack>
                            <Typography>(Max file size 25MB)</Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid container item xs={12} rowGap={1}>
                        <Grid item xs={2} className={classes.fileUploadTableCell}>
                          <Typography pl={2}>Include in Binder</Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.fileUploadTableCell}>
                          <Typography pl={2} className={classes.fileUploadTableHeaderCell}>
                            File Name
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.fileUploadTableCell}>
                          <Typography pl={2} className={classes.fileUploadTableHeaderCell}>
                            Actions
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ borderBottomWidth: "2px" }} />
                        </Grid>

                        {additionalDocuments.map((document, index) => (
                          <>
                            <Grid item xs={2} textAlign="center">
                              <ReadOnlyWrapper
                                title="Only .docx and .pdf files can be included in the  binder"
                                readOnly={!document.fileName.endsWith(".docx") && !document.fileName.endsWith(".pdf")}>
                                <FormikField
                                  component={FmuiCheckbox}
                                  type="checkbox"
                                  name={`documentsIncludedInBinder[${index}]`}
                                  disabled={!document.fileName.endsWith(".docx") && !document.fileName.endsWith(".pdf")}
                                />
                              </ReadOnlyWrapper>
                            </Grid>
                            <Grid item xs={8} className={classes.fileUploadTableCell} pl={2}>
                              {document.fileName.endsWith(".docx") ? (
                                <MicrosoftWordLink href={document.url}>{document.fileName}</MicrosoftWordLink>
                              ) : (
                                <Link href={document.url} target="_blank">
                                  {document.fileName}
                                </Link>
                              )}
                            </Grid>
                            <Grid item xs={2} textAlign="center" className={classes.fileUploadTableCell}>
                              <IconButton
                                color="error"
                                size="small"
                                disabled={formikProps.isSubmitting}
                                onClick={() => {
                                  setFileToRemove(document);
                                }}>
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </Grid>
                          </>
                        ))}
                        {filesToUpload.map((file) => (
                          <>
                            <Grid item xs={2} />
                            <Grid item xs={8} className={classes.fileUploadTableCell} pl={2}>
                              <Typography fontStyle="italic">Ready to upload: {file.name}</Typography>
                            </Grid>
                            <Grid item xs={2} textAlign="center" className={classes.fileUploadTableCell}>
                              <IconButton color="error" size="small" onClick={() => removeFile(file)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </Grid>
                          </>
                        ))}
                        {item?.isApprovedForMeeting && filesToUpload.length > 0 && (
                          <>
                            <Grid item xs={2} />
                            <Grid item xs={8} className={classes.fileUploadTableCell} pl={2}>
                              <Typography color="cpaAccentOrange.main" variant="subtitle2">
                                Newly uploaded files will not be included in the binder if it has already been assembled. You must
                                reassemble the binder or manually add them to the assembled binder from the SharePoint.
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {(dataSheet || finalReportPackage) && (
                      <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                          Other Documents
                        </Typography>
                        <Stack pl={2}>
                          {dataSheet && (
                            <div>
                              <MicrosoftWordLink href={dataSheet.url}>Data Sheet</MicrosoftWordLink>
                            </div>
                          )}
                          {finalReportPackage && (
                            <div>
                              <Link href={finalReportPackage.url} target="_blank">
                                Final Report Package
                              </Link>
                            </div>
                          )}
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                      {actionButtonIsSendForApproval || actionButtonIsApproveForMeeting ? (
                        <ReadOnlyWrapper title={actionButtonDisabledReason} readOnly={actionButtonDisabledReason !== ""}>
                          <LoadingButton
                            variant="outlined"
                            color="secondary"
                            disabled={actionButtonDisabledReason !== ""}
                            onClick={() => setApprovalDialogOpen(true)}
                            loading={approveMutationLoading}>
                            {actionButtonIsSendForApproval ? "Send for Approval" : "Approve for Meeting"}
                          </LoadingButton>
                        </ReadOnlyWrapper>
                      ) : null}
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={formikProps.submitForm}
                        loading={formikProps.isSubmitting}>
                        {item ? "Save" : "Add"}
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
                {approvalDialogOpen && (
                  <ConfirmationDialog
                    open={true}
                    body={
                      <DialogContentText>
                        {!item!.isReleasedForApproval
                          ? "Send item to registrar for approval?"
                          : "Approve item for meeting? This will also generate the datasheet for this item."}
                      </DialogContentText>
                    }
                    title={!item!.isReleasedForApproval ? "Send for approval?" : "Approve for meeting?"}
                    noDanger
                    cancel={() => setApprovalDialogOpen(false)}
                    confirm={async () => {
                      await formikProps.submitForm();
                      await approveOrSendForApproval();
                      setApprovalDialogOpen(false);
                    }}
                    loading={formikProps.isSubmitting || approveMutationLoading}
                  />
                )}
                {fileToRemove && (
                  <ConfirmationDialog
                    open={true}
                    title="Delete file"
                    body={
                      <DialogContentText>
                        Are you sure you want to delete <strong>{fileToRemove.fileName}</strong>?
                      </DialogContentText>
                    }
                    cancel={() => setFileToRemove(null)}
                    loading={formikProps.isSubmitting}
                    confirm={async () => {
                      formikProps.setSubmitting(true);
                      await deleteFile({
                        variables: {
                          itemId: item!.id,
                          attachedDocumentId: fileToRemove.id
                        }
                      });
                      formikProps.setSubmitting(false);
                      setFileToRemove(null);
                    }}
                  />
                )}
              </FormikForm>
            )}
          </Formik>
        </Paper>
      </Stack>
    </>
  );
};

export default EditRegistrationCommitteeItemTab;
