import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, CircularProgress, Collapse, IconButton, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid-pro";
import { makeStyles } from "makeStyles";
import React, { useCallback, useMemo, useState } from "react";
import { RegistrationCommitteeItem } from "registration-committee-items";
import { datagridStyles, expanderStyles } from "styles/common";
import { Link as RouterLink } from "react-router-dom";
import { MicrosoftWordLink } from "common/MicrosoftWordLink";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { useCollapsibleGrids } from "util/CollapsibleProvider";
import _ from "lodash";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { Box } from "@mui/system";
import { getRouteForRegistrationCommitteeItem, RegistrationCommitteeItemTabs } from "./RegistrationCommitteeItemScreen";
import { useMutation } from "@apollo/client";
import { ApproveFinalReportMutation, ToggleFinalReportReviewedMutation } from "./queries";
import { Permissions, useCurrentUser } from "users";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { GetMeetingsForFinalDecisionQuery } from "committee-meetings";
import { useNotifications } from "notifications";
import { DocType } from "common/DocType";
import { AxiosResponse } from "axios";
import { useAppConfig } from "util/AppConfig";
import { useAxios } from "auth/SecureAxios";
import fileDownload from "js-file-download";
import { YesNoIcon } from "common/YesNoIcon";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...expanderStyles(theme),
  highDensityDataGridRoot: {
    "& .MuiDataGrid-columnHeader": {
      padding: "0 5px"
    },
    "& .MuiDataGrid-iconSeparator": {
      transform: "scale(0.5, 3)"
    }
  }
}));

interface Props {
  mode: "enter-committee-decisions" | "review-final-reports";
  selectedMeetingId: number;
  itemsForSelectedMeeting: RegistrationCommitteeItem[];
  decisionLettersGenerated: boolean;
  height?: number;
}

const RegistrationCommitteeDecisionsTable: React.FunctionComponent<Props> = (props) => {
  const { selectedMeetingId, itemsForSelectedMeeting, decisionLettersGenerated } = props;
  const { classes, cx } = useStyles();
  const { states, setExpanded } = useCollapsibleGrids();
  const user = useCurrentUser();
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const { fileDownloadAxios } = useAxios();

  const [finalReportsToApprove, setFinalReportsToApprove] = useState<RegistrationCommitteeItem[] | null>(null);
  const [finalReportPackageForItemIdDownloading, setFinalReportPackageForItemIdDownloading] = useState<number | null>(null);

  const [toggleFinalReportReviewedMutate] = useMutation<
    {
      registrationCommitteeItem: {
        toggleFinalReportReviewed: RegistrationCommitteeItem;
      };
    },
    {
      itemId: number;
      role: "ReadyForFinalReview" | "Registrar" | "Chair";
    }
  >(ToggleFinalReportReviewedMutation);

  const toggleReviewed = useCallback(
    async (item: RegistrationCommitteeItem, role: "ReadyForFinalReview" | "Registrar" | "Chair") => {
      await toggleFinalReportReviewedMutate({
        variables: {
          itemId: item.id,
          role
        },
        optimisticResponse: {
          registrationCommitteeItem: {
            toggleFinalReportReviewed: {
              ...item,
              isFinalReportReadyForReview:
                role === "ReadyForFinalReview" ? !item.isFinalReportReadyForReview : item.isFinalReportReadyForReview,
              isFinalReportReviewedByRegistrar:
                role === "Registrar" ? !item.isFinalReportReviewedByRegistrar : item.isFinalReportReviewedByRegistrar,
              isFinalReportReviewedByChair: role === "Chair" ? !item.isFinalReportReviewedByChair : item.isFinalReportReviewedByChair
            }
          }
        }
      });
    },
    [toggleFinalReportReviewedMutate]
  );

  const [approveFinalReportsMutate, approveFinalReportsMutation] = useMutation<
    {
      registrationCommitteeItem: {
        approveFinalReports: RegistrationCommitteeItem[];
      };
    },
    {
      itemIds: number[];
    }
  >(ApproveFinalReportMutation);

  const approveFinalReports = useCallback(
    async (items: RegistrationCommitteeItem[]) => {
      const result = await approveFinalReportsMutate({
        variables: { itemIds: items.map((item) => item.id) },
        refetchQueries: [
          {
            query: GetMeetingsForFinalDecisionQuery,
            variables: { forCoordinator: false, registrationCommittees: true }
          }
        ]
      });
      if ((result.data?.registrationCommitteeItem?.approveFinalReports?.length ?? 0) > 0) {
        notifications.success(`Item${items.length === 1 ? "" : "s"} ${items.map((item) => item.entityNumber).join(", ")} approved.`);
      }
    },
    [approveFinalReportsMutate, notifications]
  );

  async function downloadFinalReportPackage(item: RegistrationCommitteeItem) {
    setFinalReportPackageForItemIdDownloading(item.id);

    let getResult: AxiosResponse<never>;
    const getRequestEndpoint = `${appConfig.apiEndpoint}/api/registration-committee-item-document/final-report-package?registrationCommitteeItemId=${item.id}`;
    try {
      getResult = await fileDownloadAxios.get(getRequestEndpoint);
      if (getResult === undefined || getResult?.status === 401) {
        getResult = await fileDownloadAxios.get(getRequestEndpoint);
      }
      if (getResult?.status === 200) {
        fileDownload(getResult.data, `Final Report Package - ${item.registeredName}.pdf`);
      } else {
        notifications.error(new Error(getResult?.statusText));
      }

      setFinalReportPackageForItemIdDownloading(null);
    } catch (e) {
      setFinalReportPackageForItemIdDownloading(null);
      notifications.error(e);
    }
  }

  function decisionIsIncomplete(item: RegistrationCommitteeItem) {
    const decision = item.decision;

    if (!decision) return true;

    return (
      (!decision.isApproved && !decision.isDeferred && !decision.isRefused) || (decision.monitoringRequired && !decision.monitoringCount)
    );
  }

  const groupedRegistrationCommitteeItems = useMemo(
    () =>
      _.groupBy(
        _.groupBy(
          _.sortBy(itemsForSelectedMeeting, (item) => item.registeredName),
          (item) => item.itemType.id
        ),
        (group) => group[0].itemType.category.id
      ),
    [itemsForSelectedMeeting]
  );

  const tableColumns: GridColumns<RegistrationCommitteeItem> = useMemo(
    () => [
      {
        field: "entityNumber",
        headerName: "Entity No.",
        headerClassName: classes.wrapHeader,
        valueGetter: ({ row }) => row.entityNumber
      },
      {
        field: "registeredName",
        headerName: "Registered Name",
        flex: 1,
        headerClassName: classes.wrapHeader,
        renderCell: ({ row }) => (
          <Tooltip title={row.registeredName}>
            <Link to={getRouteForRegistrationCommitteeItem(row, RegistrationCommitteeItemTabs.Overview)} component={RouterLink}>
              {row.registeredName}
            </Link>
          </Tooltip>
        )
      },
      {
        field: "contactName",
        headerName: "Contact Name",
        flex: 1,
        headerClassName: classes.wrapHeader
      },
      {
        field: "numAttachments",
        headerName: "Files Attached",
        headerClassName: classes.wrapHeader,
        valueGetter: ({ row }) => row.attachedDocuments.length
      },
      {
        field: "decision",
        headerName: "Decision",
        headerClassName: classes.wrapHeader,
        valueGetter: ({ row }) =>
          row.decision?.isApproved ? "Approved" : row.decision?.isDeferred ? "Deferred" : row.decision?.isRefused ? "Refused" : "--"
      },
      {
        field: "monitoringRequired",
        headerName: "Monitoring Required",
        headerClassName: classes.wrapHeader,
        renderCell: ({ row }) => (row.decision ? <YesNoIcon yes={row.decision.monitoringRequired} /> : "--"),
        align: "center"
      },
      {
        field: "decisionLetterLink",
        headerName: "Decision Letter",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        width: 64,
        align: "center",
        sortable: false,
        hide: !decisionLettersGenerated,
        renderCell: ({ row }) => {
          const item = row;
          const decisionLetter = item.attachedDocuments.filter((ad) => ad.type === DocType.RegistrationCommitteeItemDecisionLetter)[0];
          return decisionLetter ? (
            <MicrosoftWordLink href={decisionLetter.url}>{item.isCompleted ? "View" : "Edit"}</MicrosoftWordLink>
          ) : null;
        }
      },
      {
        field: "finalReportLink",
        headerName: "Final Report Package",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 86,
        sortable: false,
        hide: !decisionLettersGenerated,
        renderCell: ({ row }) => {
          const item = row;
          const finalReportPackage = item.attachedDocuments.filter(
            (ad) => ad.type === DocType.RegistrationCommittteeItemFinalReportPackage
          )[0];
          return item.isCompleted && finalReportPackage?.url ? (
            <Link href={finalReportPackage.url} target="_blank">
              View
            </Link>
          ) : finalReportPackageForItemIdDownloading === item.id ? (
            <CircularProgress size="small" />
          ) : (
            <Link component="button" variant="body2" onClick={() => downloadFinalReportPackage(item)}>
              View
            </Link>
          );
        }
      },
      {
        field: "editDecisionLink",
        headerName: "Edit Decision",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 62,
        sortable: false,
        renderCell: ({ row }) => (
          <Tooltip
            title={
              decisionIsIncomplete(row)
                ? "This item requires additional information its decision before its decision letter can be generated."
                : "Edit Decision"
            }>
            <Link to={getRouteForRegistrationCommitteeItem(row, RegistrationCommitteeItemTabs.Decision)} component={RouterLink}>
              <IconButton color={decisionIsIncomplete(row) ? "error" : "primary"} size="small">
                <FontAwesomeIcon icon={faArrowRight} />
              </IconButton>
            </Link>
          </Tooltip>
        )
      }
    ],
    [decisionLettersGenerated]
  );

  const reviewedColumns: GridColumns<RegistrationCommitteeItem> = useMemo(
    () =>
      props.mode === "review-final-reports"
        ? [
            {
              field: "isFinalReportReviewedByRegistrar",
              headerName: "Registrar Reviewed",
              headerAlign: "center",
              headerClassName: classes.wrapHeader,
              width: 72,
              align: "center",
              hide: !props.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const item = row;
                const userCanToggle = user.userHasPermission(Permissions.RegComItemDecisionLetterRegistrarReview);
                const disabledReason = item.isCompleted
                  ? "This item has already been completed."
                  : !userCanToggle
                  ? "Only the Registrar can mark item as reviewed by Registrar."
                  : decisionIsIncomplete(item)
                  ? "The committee decision is incomplete."
                  : "";

                return (
                  <Tooltip title={disabledReason}>
                    <span>
                      <Checkbox
                        checked={item.isFinalReportReviewedByRegistrar}
                        disabled={disabledReason !== ""}
                        onClick={() => toggleReviewed(item, "Registrar")}
                      />
                    </span>
                  </Tooltip>
                );
              }
            },
            {
              field: "isFinalReportReviewedByChair",
              headerName: "Chair Reviewed",
              headerAlign: "center",
              headerClassName: classes.wrapHeader,
              width: 72,
              align: "center",
              hide: !props.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const item = row;
                const userCanToggle = user.userHasPermission(Permissions.RegComItemDecisionLetterChairReview);
                const disabledReason = item.isCompleted
                  ? "This item has already been completed."
                  : !item.isFinalReportReviewedByRegistrar
                  ? "The Registrar has not reviewed the final report yet."
                  : !userCanToggle
                  ? "Only the Chair can mark item as reviewed by Chair."
                  : decisionIsIncomplete(item)
                  ? "The committee decision is incomplete."
                  : "";

                return (
                  <Tooltip title={disabledReason}>
                    <span>
                      <Checkbox
                        checked={item.isFinalReportReviewedByChair}
                        disabled={disabledReason !== ""}
                        onClick={() => toggleReviewed(item, "Chair")}
                      />
                    </span>
                  </Tooltip>
                );
              }
            },
            {
              field: "Approve",
              headerName: "",
              align: "center",
              width: 120,
              sortable: false,
              hide: !props.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const item = row;
                const userCanClick = user.userHasPermission(Permissions.RegComItemDecisionLetterFinalApproval);
                const disabledReason =
                  !item.isFinalReportReviewedByRegistrar || !item.isFinalReportReviewedByChair
                    ? "The item must be marked as reviewed by the Registrar and Chair."
                    : !userCanClick
                    ? "You do not have permission to give final approval for these items."
                    : decisionIsIncomplete(item)
                    ? "The committee decision is incomplete."
                    : "";

                if (item.isCompleted) {
                  return <Typography>Completed</Typography>;
                }
                return (
                  <Tooltip title={disabledReason}>
                    <span>
                      <Button variant="outlined" disabled={disabledReason !== ""} onClick={() => setFinalReportsToApprove([item])}>
                        Approve
                      </Button>
                    </span>
                  </Tooltip>
                );
              }
            }
          ]
        : [
            {
              field: "isFinalReportReadyForReview",
              headerName: "Reviewed",
              headerAlign: "center",
              width: 72,
              align: "center",
              hide: !props.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const item = row;

                return (
                  <Tooltip title={decisionIsIncomplete(item) ? "The committee decision is incomplete." : ""}>
                    <span>
                      <Checkbox
                        checked={item.isFinalReportReadyForReview}
                        disabled={decisionIsIncomplete(item)}
                        onClick={() => toggleReviewed(item, "ReadyForFinalReview")}
                      />
                    </span>
                  </Tooltip>
                );
              }
            }
          ],
    [props.decisionLettersGenerated, toggleReviewed, user]
  );

  const storageKey = `CommitteeDecisions:${selectedMeetingId} RegistrationCommitteeItems`;
  const expanded = states[storageKey] ?? true;

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton size="small" onClick={() => setExpanded(storageKey, !expanded)}>
          <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
        </IconButton>
        <Typography variant="h2">Committee Items</Typography>
      </Stack>
      <Collapse in={expanded}>
        <Paper sx={(theme) => ({ p: 2, [theme.breakpoints.up("lg")]: { height: props.height, overflow: "scroll" } })}>
          <Stack spacing={5}>
            {Object.keys(groupedRegistrationCommitteeItems).map((key) => {
              const group = groupedRegistrationCommitteeItems[key];
              const totalItems = group.reduce((sum, items) => sum + items.length, 0);
              const category = group[0][0].itemType.category.name;
              const categoryStorageKey = `${storageKey} $category`;
              const categoryExpanded = states[categoryStorageKey] ?? true;
              return (
                <div>
                  <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <IconButton size="small" onClick={() => setExpanded(categoryStorageKey, !categoryExpanded)}>
                      <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: categoryExpanded })} />
                    </IconButton>
                    <Typography variant="h3" color="primary">
                      {category}
                    </Typography>
                    <Typography color="primary">
                      {totalItems} Item{totalItems !== 1 ? "s" : ""}
                    </Typography>
                  </Stack>
                  <Collapse in={categoryExpanded}>
                    <Stack spacing={2}>
                      {group.map((items) => {
                        return (
                          <Box sx={{ pl: 3, pr: 3 }}>
                            <DataGridWithHeader
                              title={<Typography variant="h4">{items[0].itemType.name}</Typography>}
                              headerAlign="center"
                              allowHidingColumns
                              displayWithoutContainer
                              deemphasizeHeader
                              collapsible
                              storageKey={`${categoryStorageKey} - ${items[0].itemType.name}`}
                              className={cx([classes.datagridRoot, classes.highDensityDataGridRoot])}
                              disableSelectionOnClick
                              rows={items}
                              columns={[...tableColumns, ...reviewedColumns]}
                              pinnedColumns={{ left: ["entityNumber", "registeredName"] }}
                            />
                          </Box>
                        );
                      })}
                    </Stack>
                  </Collapse>
                </div>
              );
            })}
          </Stack>
        </Paper>
      </Collapse>
      <ConfirmationDialog
        open={!!finalReportsToApprove}
        title="Approve final reports and/or decision letters?"
        body="Once you approve an item it will be marked as completed and the decision cannot be changed."
        confirmButtonText="Continue"
        noDanger
        loading={approveFinalReportsMutation.loading}
        confirm={async () => {
          await approveFinalReports(finalReportsToApprove!);
          setFinalReportsToApprove(null);
        }}
        cancel={() => {
          setFinalReportsToApprove(null);
        }}
      />
    </>
  );
};

export default RegistrationCommitteeDecisionsTable;
