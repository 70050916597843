import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { ScreenHeader } from "common/ScreenHeader";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { dataGridDateValueFormatter, formatDate } from "util/formats";
import { DeleteButton } from "../common/CrudTable";
import { Helmet } from "react-helmet";
import {
  AvailableMeetingsQuery,
  CommitteeMeeting,
  DeleteCommitteeMeetingMutation,
  FetchCommitteeMeetingsQuery,
  FetchBinderJobsQuery,
  FetchUnassignedPrsQuery,
  ToggleCommitteeMeetingClosedMutation,
  BinderJob,
  PastPeriod,
  FetchUnassignedRegistrationCommitteeItemsQuery
} from ".";
import { datagridStyles, tableStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import { PracticeReview } from "../practice-reviews";
import { ChangeMeetingDialog } from "./ChangeMeetingDialog";
import { DataGridWithHeader } from "../common/DataGridWithHeader";
import PrLink from "../common/PrLink";
import { GridCellParams, GridColDef, GridSortCellParams } from "@mui/x-data-grid-pro";
import { Cx } from "tss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { useNotifications } from "../notifications";
import { useSignalR } from "util/useSignalR";
import { optionalScreenWidthLimit } from "styles/theme";
import BinderJobCard, { subscribeToDocumentGenerationProgress } from "./BinderJobCard";
import AddMeetingDialog from "./AddMeetingDialog";
import { AppRoute } from "../Routes";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { useHistory } from "react-router-dom";
import { CollapsibleProvider } from "util/CollapsibleProvider";
import { RegistrationCommitteeItem } from "registration-committee-items";
import DOMPurify from "dompurify";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { Link as RouterLink } from "react-router-dom";
import {
  getRouteForRegistrationCommitteeItem,
  RegistrationCommitteeItemTabs
} from "registration-committee-items/RegistrationCommitteeItemScreen";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  binderCard: {
    position: "relative"
  },
  dismissButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5)
  }
}));

export const getRegistrationCommitteeColumns = (classes: any): GridColDef[] => [
  {
    field: "createdDate",
    headerName: "Date",
    headerClassName: classes.wrapHeader,
    type: "date",
    width: 100,
    valueGetter: (params) => (params.row as RegistrationCommitteeItem).createdDate,
    valueFormatter: dataGridDateValueFormatter
  },
  {
    field: "registeredName",
    headerName: "Registered Name",
    flex: 1,
    renderCell: (params) => (
      <Tooltip title={(params.row as RegistrationCommitteeItem).registeredName}>
        <Link
          to={getRouteForRegistrationCommitteeItem(params.row as RegistrationCommitteeItem, RegistrationCommitteeItemTabs.Overview)}
          component={RouterLink}>
          {(params.row as RegistrationCommitteeItem).registeredName}
        </Link>
      </Tooltip>
    ),
    sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
      param1.api.getRow(param1.id)!.registeredName.localeCompare(param2.api.getRow(param2.id)!.registeredName)
  },
  {
    field: "itemType.name",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => (
      <Tooltip title={(params.row as RegistrationCommitteeItem).itemType.name}>
        <span>{(params.row as RegistrationCommitteeItem).itemType.name}</span>
      </Tooltip>
    ),
    sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
      param1.api.getRow(param1.id)!.itemType.name.localeCompare(param2.api.getRow(param2.id)!.itemType.name)
  },
  {
    field: "entityNumber",
    headerName: "Entity No.",
    width: 100
  },
  {
    field: "filesAttached",
    headerName: "Files Attached",
    width: 120,
    valueGetter: (params) => (params.row as RegistrationCommitteeItem).attachedDocuments.length
  },
  {
    field: "notes",
    headerName: "Notes",
    flex: 2,
    renderCell: (params) => {
      const cleanHtml = DOMPurify.sanitize((params.row as RegistrationCommitteeItem).notes ?? "");
      return <span dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
    }
  }
];

export const getCommonMeetingFileColumns = (classes: any, cx: Cx): GridColDef[] => [
  {
    field: "prNumber",
    headerName: "PR No.",
    width: 80,
    renderCell: (params) => <PrLink practiceReview={params.row as PracticeReview} />,
    sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
      param1.api.getRow(param1.id)!.prNumber.localeCompare(param2.api.getRow(param2.id)!.prNumber)
  },
  {
    field: "firm.name",
    headerName: "Firm Name",
    flex: 1,
    renderCell: (params) => (
      <Tooltip title={(params.row as PracticeReview).firm.name}>
        <span>{(params.row as PracticeReview).firm.name}</span>
      </Tooltip>
    ),
    sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
      param1.api.getRow(param1.id)!.firm.name.localeCompare(param2.api.getRow(param2.id)!.firm.name)
  },
  {
    field: "startDate",
    headerName: "Review Date",
    headerClassName: classes.wrapHeader,
    type: "date",
    width: 100,
    valueGetter: (params) => (params.row as PracticeReview).startDate,
    valueFormatter: dataGridDateValueFormatter
  },
  {
    field: "committeeMeetingIsConfirmed",
    headerName: "Confirmed",
    type: "boolean",
    width: 100
  },
  {
    field: "hasRedactedFirmResponse",
    headerName: "Redacted Response",
    headerClassName: classes.wrapHeader,
    type: "boolean",
    width: 80
  },
  {
    field: "reviewType",
    headerName: "Type",
    width: 100
  },
  {
    field: "mostAuthoritativeDecision.isComply",
    headerName: "Assessment",
    width: 100,
    valueGetter: (params) =>
      (params.row as PracticeReview).mostAuthoritativeDecision
        ? (params.row as PracticeReview).mostAuthoritativeDecision!.isComply
          ? "Comply"
          : "Non-comply"
        : "--",
    cellClassName: (params: GridCellParams) =>
      cx({
        [classes.comply]: (params.row as PracticeReview).mostAuthoritativeDecision?.isComply === true,
        [classes.nonComply]: (params.row as PracticeReview).mostAuthoritativeDecision?.isComply === false
      })
  },
  {
    field: "mostAuthoritativeDecision.complianceZone.zoneCode",
    headerName: "Zone",
    width: 60,
    valueGetter: (params) =>
      (params.row as PracticeReview).mostAuthoritativeDecision?.complianceZone
        ? (params.row as PracticeReview).mostAuthoritativeDecision!.complianceZone!.zoneCode
        : "--"
  },
  {
    field: "mostAuthoritativeDecision.motionStandard.motionCode",
    headerName: "Standard Motion",
    headerClassName: classes.wrapHeader,
    width: 80,
    valueGetter: (params) => (params.row as PracticeReview).mostAuthoritativeDecision?.motionStandard?.motionCode ?? "--"
  },
  {
    field: "firm.cpab",
    headerName: "CPAB",
    type: "boolean",
    width: 60,
    valueGetter: (params) => (params.row as PracticeReview).firm.isCpab
  }
];

interface Props {
  registrationCommittees?: boolean;
}

const CommitteeMeetingsScreen: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();
  const history = useHistory();

  const [showPastMeetings, setShowPastMeetings] = useState(false);
  const [pastPeriod, setPastPeriod] = useState(PastPeriod.OneYear);

  const [reassigningRegistrationCommitteeItems, setReassigningRegistrationCommitteeItems] = useState<RegistrationCommitteeItem[] | null>(
    null
  );
  const [reassigningPrs, setReassigningPrs] = useState<PracticeReview[] | null>(null);
  const [addingMeeting, setAddingMeeting] = useState(false);
  const [updatingMeeting, setUpdatingMeeting] = useState<CommitteeMeeting | null>(null);
  const [deletingId, setDeletingId] = useState<any | null>(null);

  const meetingsQuery = useQuery<
    { committeeMeetings: CommitteeMeeting[] },
    { excludeFuture: boolean; pastPeriod: PastPeriod | null; registrationCommittee: boolean }
  >(FetchCommitteeMeetingsQuery, {
    variables: {
      excludeFuture: showPastMeetings,
      pastPeriod: showPastMeetings ? pastPeriod : null,
      registrationCommittee: !!props.registrationCommittees
    }
  });
  const meetings = _.orderBy(
    meetingsQuery.data?.committeeMeetings ?? [],
    [(m) => m.meetingDate, (m) => m.location],
    [showPastMeetings ? "desc" : "asc", "asc"]
  );

  const apolloClient = useApolloClient();
  const binderJobsQuery = useQuery<{ binderJobs: BinderJob[] }>(FetchBinderJobsQuery, { fetchPolicy: "network-only" });

  const signalrPubSub = useSignalR();
  useEffect(() => {
    subscribeToDocumentGenerationProgress(signalrPubSub, apolloClient, binderJobsQuery.loading);
  }, [signalrPubSub, binderJobsQuery]);

  const [deleteMutate, deleteMutation] = useMutation<{ committeeMeeting: { delete: CommitteeMeeting } }, { meetingId: number }>(
    DeleteCommitteeMeetingMutation,
    {
      refetchQueries: [
        {
          query: FetchCommitteeMeetingsQuery,
          variables: {
            excludeFuture: showPastMeetings,
            pastPeriod: showPastMeetings ? pastPeriod : null,
            registrationCommittee: !!props.registrationCommittees
          }
        }
      ]
    }
  );

  async function deleteMeeting(meetingId: number) {
    const result = await deleteMutate({ variables: { meetingId } });

    setDeletingId(null);

    if (result.data?.committeeMeeting.delete.id) {
      notifications.success("Deleted meeting.");
    }
  }

  const [toggleMeetingClosedMutate, closeMeetingMutation] = useMutation<
    { committeeMeeting: { toggleClosed: CommitteeMeeting } },
    { meetingId: number }
  >(ToggleCommitteeMeetingClosedMutation, {
    refetchQueries: [{ query: AvailableMeetingsQuery }]
  });

  async function toggleMeetingClosed(meeting: CommitteeMeeting) {
    setUpdatingMeeting(meeting);
    const result = await toggleMeetingClosedMutate({
      variables: {
        meetingId: meeting.id
      }
    });
    setUpdatingMeeting(null);

    if (result.data?.committeeMeeting.toggleClosed.id) {
      notifications.success(meeting.isOpen ? "Meeting closed." : "Meeting reopened.");
    }
  }

  const MeetingMenu: React.FunctionComponent<{ meeting: CommitteeMeeting }> = (menuProps) => {
    const { meeting } = menuProps;
    const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

    return (
      <Box sx={{ ml: 1, mr: 2 }}>
        {updatingMeeting?.id === meeting.id ? (
          <Box sx={{ p: "5px", display: "flex", alignItems: "center" }}>
            <CircularProgress size="1.125rem" />
          </Box>
        ) : (
          <IconButton
            onClick={(e) => {
              setMenuAnchorElement(e.currentTarget);
              e.stopPropagation();
            }}
            size="small">
            <FontAwesomeIcon icon={faEllipsisV} />
          </IconButton>
        )}
        <Menu
          id={`meeting-menu-${meeting.id}`}
          anchorEl={menuAnchorElement}
          keepMounted
          open={!!menuAnchorElement}
          onClose={() => setMenuAnchorElement(null)}>
          <MenuItem
            onClick={() => {
              setMenuAnchorElement(null);
              toggleMeetingClosed(meeting);
            }}>
            {meeting.isOpen ? "Close Meeting" : "Reopen Meeting"}
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  const UnassignedRegistrationCommitteeItemsList: React.FunctionComponent<{ items: RegistrationCommitteeItem[] }> = (componentProps) => {
    const [selectingUnassigned, setSelectingUnassigned] = useState(false);
    const [selectedUnassigned, setSelectedUnassigned] = useState<RegistrationCommitteeItem[]>([]);

    return (
      <DataGridWithHeader
        title="Unassigned Registration Committee"
        rows={componentProps.items}
        columns={getRegistrationCommitteeColumns(classes)}
        noDataMessage="No unassigned items."
        collapsible
        storageKey="Unassigned Registration Committee"
        headerActions={
          <Stack direction="row" spacing={3}>
            {selectingUnassigned ? (
              <>
                <Button
                  onClick={() => {
                    setSelectingUnassigned(false);
                    setSelectedUnassigned([]);
                  }}
                  size="small"
                  variant="outlined">
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    setReassigningRegistrationCommitteeItems(selectedUnassigned);
                    setSelectingUnassigned(false);
                    setSelectedUnassigned([]);
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={selectedUnassigned.length === 0}>
                  Assign Items
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectingUnassigned(true);
                  setSelectedUnassigned([]);
                }}>
                Select For Assignment
              </Button>
            )}
          </Stack>
        }
        checkboxSelection={selectingUnassigned}
        selectionModel={selectedUnassigned.map((f) => f.id)}
        onSelectionModelChange={(newSelectionModel) =>
          setSelectedUnassigned(componentProps.items.filter((item) => newSelectionModel.indexOf(item.id) !== -1))
        }
        disableSelectionOnClick
      />
    );
  };

  const UnassignedPrList: React.FunctionComponent<{ pprpProgramReviews: boolean; prs: PracticeReview[] }> = (componentProps) => {
    const [selectingUnassigned, setSelectingUnassigned] = useState(false);
    const [selectedUnassigned, setSelectedUnassigned] = useState<PracticeReview[]>([]);

    return (
      <DataGridWithHeader
        title={componentProps.pprpProgramReviews ? "Unassigned PPRP Program Reviews" : "Unassigned Practice Reviews"}
        rows={componentProps.prs}
        columns={getCommonMeetingFileColumns(classes, cx)}
        noDataMessage="No unassigned reviews."
        collapsible
        storageKey={componentProps.pprpProgramReviews ? "Unassigned PPRP Program Reviews" : "Unassigned Practice Reviews"}
        headerActions={
          <Stack direction="row" spacing={3}>
            {selectingUnassigned ? (
              <>
                <Button
                  onClick={() => {
                    setSelectingUnassigned(false);
                    setSelectedUnassigned([]);
                  }}
                  size="small"
                  variant="outlined">
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    setReassigningPrs(selectedUnassigned);
                    setSelectingUnassigned(false);
                    setSelectedUnassigned([]);
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={selectedUnassigned.length === 0}>
                  Assign PRs
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectingUnassigned(true);
                  setSelectedUnassigned([]);
                }}>
                Select for assignment
              </Button>
            )}
          </Stack>
        }
        checkboxSelection={selectingUnassigned}
        selectionModel={selectedUnassigned.map((f) => f.id)}
        onSelectionModelChange={(newSelectionModel) =>
          setSelectedUnassigned(componentProps.prs.filter((pr) => newSelectionModel.indexOf(pr.id) !== -1))
        }
        disableSelectionOnClick
      />
    );
  };

  const unassignedPrsQuery = useQuery<{ unassignedPrs: PracticeReview[] }, { registrationCommittee: boolean }>(FetchUnassignedPrsQuery, {
    variables: { registrationCommittee: !!props.registrationCommittees }
  });
  const [queryUnassignedRegistrationCommitteeItems, unassignedRegistrationCommitteeItemsQuery] = useLazyQuery<{
    registrationCommitteeItems: RegistrationCommitteeItem[];
  }>(FetchUnassignedRegistrationCommitteeItemsQuery);

  useEffect(() => {
    if (props.registrationCommittees) {
      queryUnassignedRegistrationCommitteeItems();
    }
  }, [props.registrationCommittees, queryUnassignedRegistrationCommitteeItems]);

  const unassignedPrs = unassignedPrsQuery.data?.unassignedPrs ?? [];
  const unassignedPrcReviews = unassignedPrs.filter((pr) => !pr.isPprpProgramReview);
  const unassignedPprpProgramReviews = unassignedPrs.filter((pr) => pr.isPprpProgramReview);
  const unassignedRegistrationCommitteeItems = unassignedRegistrationCommitteeItemsQuery.data?.registrationCommitteeItems ?? [];

  const binderJobs = _.orderBy(binderJobsQuery.data?.binderJobs, [(b) => b.isComplete, (b) => b.dateCreated], ["asc", "desc"]) ?? [];

  return (
    <CollapsibleProvider screen="committeeMeetings">
      <Helmet>
        <title>Committee Meetings - PRS Online</title>
      </Helmet>

      <ScreenHeader title={`${props.registrationCommittees ? "Registration" : "Practice Review"} Committee Meetings`} />

      {binderJobs.length > 0 && (
        <Stack spacing={2} sx={{ mb: 5, maxWidth: optionalScreenWidthLimit }}>
          {binderJobs.map((job) => {
            return <BinderJobCard key={job.id} job={job} />;
          })}
        </Stack>
      )}

      {unassignedRegistrationCommitteeItems.length > 0 && (
        <Box sx={{ mb: 5 }}>
          <UnassignedRegistrationCommitteeItemsList items={unassignedRegistrationCommitteeItems} />
        </Box>
      )}
      {unassignedPrcReviews.length > 0 && (
        <Box sx={{ mb: 5 }}>
          <UnassignedPrList pprpProgramReviews={false} prs={unassignedPrcReviews} />
        </Box>
      )}
      {unassignedPprpProgramReviews.length > 0 && (
        <Box sx={{ mb: 5 }}>
          <UnassignedPrList pprpProgramReviews={true} prs={unassignedPprpProgramReviews} />
        </Box>
      )}

      <DataGridWithHeader
        title="Meetings"
        rows={meetings}
        loading={meetingsQuery.loading}
        headerActions={
          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={0}>
              <FormControlLabel
                control={<Switch checked={showPastMeetings} onClick={() => setShowPastMeetings(!showPastMeetings)} />}
                label="Past Meetings"
              />
              <ToggleButtonGroup size="small" value={showPastMeetings ? pastPeriod : undefined} exclusive disabled={!showPastMeetings}>
                <ToggleButton value={PastPeriod.OneYear} key={PastPeriod.OneYear} onClick={() => setPastPeriod(PastPeriod.OneYear)}>
                  Last Year
                </ToggleButton>
                <ToggleButton value={PastPeriod.AllTime} key={PastPeriod.AllTime} onClick={() => setPastPeriod(PastPeriod.AllTime)}>
                  All Time
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Button variant="outlined" color="primary" onClick={() => setAddingMeeting(true)}>
              Add New
            </Button>
          </Stack>
        }
        noDataMessage="No meetings."
        columns={[
          {
            field: "meetingDate",
            headerName: "Date",
            valueFormatter: (params) => formatDate(params.value as string)
          },
          {
            field: "location",
            headerName: "Location",
            flex: 1
          },
          {
            field: "meetingType",
            headerName: "Meeting Type",
            width: 120,
            valueGetter: (params: { row: CommitteeMeeting }) => (params.row.isRegistrationCommittee ? "Registration" : "Practice Review")
          },
          {
            field: "filesAllocated",
            headerName: "Files Allocated",
            width: 120,
            align: "right",
            headerAlign: "right",
            valueGetter: (params) => (params.row as CommitteeMeeting).assignedReviews.length
          },
          {
            field: "reviewQuota",
            headerName: "Maximum to Allocate",
            width: 140,
            align: "right",
            headerAlign: "right"
          },
          {
            field: "leftToAllocate",
            headerName: "Left to Allocate",
            width: 120,
            align: "right",
            headerAlign: "right",
            valueGetter: (params) => (params.row as CommitteeMeeting).reviewQuota - (params.row as CommitteeMeeting).assignedReviews.length
          },
          {
            field: "zone1FileCount",
            headerName: "Zone 1 Files",
            headerClassName: classes.wrapHeader,
            align: "right",
            headerAlign: "right",
            width: 100,
            valueGetter: (params) =>
              (params.row as CommitteeMeeting).assignedReviews.filter((r) =>
                /^1/.test(r.mostAuthoritativeDecision?.complianceZone?.zoneCode ?? "")
              ).length
          },
          {
            field: "zone2FileCount",
            headerName: "Zone 2 Files",
            headerClassName: classes.wrapHeader,
            align: "right",
            headerAlign: "right",
            width: 100,
            valueGetter: (params) =>
              (params.row as CommitteeMeeting).assignedReviews.filter((r) =>
                /^2/.test(r.mostAuthoritativeDecision?.complianceZone?.zoneCode ?? "")
              ).length
          },
          {
            field: "zone3FileCount",
            headerName: "Zone 3 Files",
            headerClassName: classes.wrapHeader,
            align: "right",
            headerAlign: "right",
            width: 100,
            valueGetter: (params) =>
              (params.row as CommitteeMeeting).assignedReviews.filter((r) =>
                /^3/.test(r.mostAuthoritativeDecision?.complianceZone?.zoneCode ?? "")
              ).length
          },
          {
            field: "status",
            headerName: "Status",
            valueGetter: (params) => {
              const meeting = params.row as CommitteeMeeting;
              return meeting.status;
            }
          },
          {
            field: "sharePointLink",
            headerName: "SharePoint",
            headerAlign: "center",
            headerClassName: classes.wrapHeader,
            align: "center",
            width: 90,
            renderCell: (params) => {
              const meeting = params.row as CommitteeMeeting;
              return (
                <Link href={meeting.sharePointUrl} target="_blank">
                  SharePoint
                </Link>
              );
            }
          },
          {
            field: "Actions",
            renderCell: (params: { row: CommitteeMeeting }) => {
              const deleteDisabledReason =
                params.row.assignedReviews.length > 0
                  ? "Cannot delete a meeting with PRs assigned."
                  : params.row.assignedRegistrationCommitteeItems.length > 0
                  ? "Cannot delete a meeting with Registration Committee items assigned."
                  : undefined;

              return (
                <Stack direction="row" spacing={1}>
                  <MeetingMenu meeting={params.row} />
                  <IconButton
                    color="primary"
                    size="small"
                    title="Go"
                    onClick={() => history.push(AppRoute.CommitteeMeeting.replace(":id", params.row.id.toString()))}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </IconButton>
                  <DeleteButton disabledReason={deleteDisabledReason} onClick={() => setDeletingId(params.row.id)} />
                </Stack>
              );
            },
            align: "center",
            headerAlign: "center",
            sortable: false,
            width: 150
          }
        ]}
      />

      {addingMeeting && (
        <AddMeetingDialog handleClose={() => setAddingMeeting(false)} registrationCommittee={!!props.registrationCommittees} />
      )}

      {reassigningRegistrationCommitteeItems && (
        <ChangeMeetingDialog
          registrationCommitteeItems={reassigningRegistrationCommitteeItems}
          onClose={() => setReassigningRegistrationCommitteeItems(null)}
          meetingType="registration-committee"
        />
      )}
      {reassigningPrs && (
        <ChangeMeetingDialog
          practiceReviews={reassigningPrs}
          onClose={() => setReassigningPrs(null)}
          meetingType={props.registrationCommittees ? "registration-committee" : "prc"}
        />
      )}

      {deletingId && (
        <ConfirmationDialog
          open={true}
          title="Delete Meeting?"
          body={<DialogContentText>{`Delete ${meetings.find((m) => m.id === deletingId)!.name}?`}</DialogContentText>}
          confirm={() => deleteMeeting(deletingId)}
          cancel={() => setDeletingId(null)}
          loading={deleteMutation.loading}
        />
      )}
    </CollapsibleProvider>
  );
};

export default CommitteeMeetingsScreen;
