import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContentText,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { GridCellParams, GridCheckIcon, GridColumns } from "@mui/x-data-grid-pro";
import { ScreenHeader } from "common/ScreenHeader";
import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import {
  FetchCommitteeMeetingToEnterDecisionsQuery,
  GenerateDecisionLettersMutation,
  GetMeetingsForFinalDecisionQuery,
  ToggleFinalReportReviewedMutation,
  ToggleTabledLetterReviewedMutation
} from ".";
import { CommitteeMeeting, CommitteeMeetingTabledReview, ReleaseFinalReportsForDirectorReviewMutation } from "committee-meetings";
import { makeStyles } from "makeStyles";
import { ApproveFinalReportsMutation, PracticeReview, PRBaseStatusCode } from "practice-reviews";
import useLocalStorage from "util/useLocalStorage";
import { datagridStyles } from "styles/common";
import { useHistory, useLocation } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "../practice-reviews/PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { MicrosoftWordLink } from "../common/MicrosoftWordLink";
import { CommitteeMeetingRegretsDialog } from "../committee-meetings/CommitteeMeetingRegretsDialog";
import { useNotifications } from "../notifications";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import fileDownload from "js-file-download";
import { useAxios } from "../auth/SecureAxios";
import { useAppConfig } from "../util/AppConfig";
import { Link as RouterLink } from "react-router-dom";
import { DecisionTypeCode } from "../decisions";
import { AxiosResponse } from "axios";
import RegistrationCommitteeDecisionsTable from "registration-committee-items/RegistrationCommitteeDecisionsTable";
import { CollapsibleProvider } from "util/CollapsibleProvider";
import { DataGridWithHeader } from "common/DataGridWithHeader";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  highDensityDataGridRoot: {
    "& .MuiDataGrid-columnHeader": {
      padding: "0 5px"
    },
    "& .MuiDataGrid-iconSeparator": {
      transform: "scale(0.5, 3)"
    }
  }
}));

interface Props {
  mode: "enter-committee-decisions" | "review-final-reports";
  registrationCommittees?: boolean;
}

const CommitteeDecisionsScreen: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const notifications = useNotifications();
  const { fileDownloadAxios } = useAxios();
  const appConfig = useAppConfig();

  const [selectedMeetingId, setSelectedMeetingId] = useLocalStorage<number | null>("SelectedMeetingIdForCommitteeDecisions", null);
  const [enteringRegrets, setEnteringRegrets] = useState(false);
  const [finalReportsToApprove, setFinalReportsToApprove] = useState<PracticeReview[] | null>(null);
  const [finalReportPackageForPrIdDownloading, setFinalReportPackageForPrIdDownloading] = useState<number | null>(null);
  const [regenerateLettersDialogOpen, setRegenerateLettersDialogOpen] = useState(false);

  useEffect(() => {
    // Select the meeting whose ID is on the query string, if any
    const requestedMeetingId = new URLSearchParams(location.search).get("meetingId");
    if (requestedMeetingId) {
      setSelectedMeetingId(Number(requestedMeetingId));
    }
  }, []);

  const meetingsQuery = useQuery<
    { committeeMeetingsForFinalDecision: CommitteeMeeting[] },
    { forCoordinator: boolean; registrationCommittees: boolean }
  >(GetMeetingsForFinalDecisionQuery, {
    variables: {
      forCoordinator: props.mode === "enter-committee-decisions",
      registrationCommittees: props.registrationCommittees ?? false
    }
  });
  const meetings = meetingsQuery.data?.committeeMeetingsForFinalDecision ?? [];
  const selectedMeeting = selectedMeetingId ? meetings.find((m) => m.id === selectedMeetingId) : null;

  useEffect(() => {
    // Deselect the selected meeting if it's no longer present in the meeting list, which can occur after refreshing the query
    if (!meetingsQuery.loading && meetings.every((m) => m.id !== selectedMeetingId)) {
      setSelectedMeetingId(null);
    }

    // Select a meeting if it's the only one in the list.
    if (meetings.length === 1) {
      setSelectedMeetingId(meetings[0].id);
    }
  }, [meetings, meetingsQuery.loading, selectedMeetingId, setSelectedMeetingId]);

  const meetingWithFilesQuery = useQuery<{ committeeMeetingById: CommitteeMeeting }, { meetingId: number; meetingDate: string }>(
    FetchCommitteeMeetingToEnterDecisionsQuery,
    {
      variables: {
        meetingId: selectedMeeting?.id ?? 0,
        meetingDate: selectedMeeting?.meetingDate ?? ""
      },
      skip: !Boolean(selectedMeeting)
    }
  );
  const orderedMeetingPrs = orderMeetingPrs(meetingWithFilesQuery.data?.committeeMeetingById.assignedReviews ?? []);
  const tabledPrs = (meetingWithFilesQuery.data?.committeeMeetingById.tabledReviews ?? []).map((cmtr) => cmtr.practiceReview);
  const orderedTabledPrs = orderMeetingPrs(tabledPrs);
  const prsForSelectedMeeting = orderedMeetingPrs.concat(orderedTabledPrs);
  const prsReadyForFinalApproval = prsForSelectedMeeting.filter((pr) => pr.isFinalReportReviewedByDirector && !prIsCompleted(pr));

  const itemsForSelectedMeeting = meetingWithFilesQuery.data?.committeeMeetingById.assignedRegistrationCommitteeItems ?? [];
  const hasItemsInMeeting = itemsForSelectedMeeting.length > 0;

  const fillScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const sectionMinHeight =
    hasItemsInMeeting && prsForSelectedMeeting.length > 0
      ? fillScreen
        ? (window.innerHeight - 550) / 2
        : undefined
      : window.innerHeight - 450;

  function orderMeetingPrs(prs: PracticeReview[]) {
    return _.orderBy(
      prs,
      [
        (pr) => (pr.tabledFromPrevious ? pr.prNumber : 999999),
        (pr) => pr.mostAuthoritativeDecision?.isComply !== undefined,
        (pr) => pr.mostAuthoritativeDecision?.isComply ?? true,
        (pr) => pr.mostAuthoritativeDecision?.motionStandard?.sortOrder ?? 999,
        (f) => f.prNumber
      ],
      ["asc", "desc", "asc", "asc", "asc"]
    );
  }

  function committeeDecisionIsMissing(practiceReview: PracticeReview) {
    const decision = practiceReview.mostAuthoritativeDecision;
    return !decision || decision.decisionType.typeCode !== DecisionTypeCode.Committee;
  }

  function decisionIsIncomplete(practiceReview: PracticeReview) {
    if (practiceReview.tabledToNext) {
      return false;
    }

    const decision = practiceReview.mostAuthoritativeDecision;

    if (!decision || decision.decisionType.typeCode !== DecisionTypeCode.Committee) return true;

    return (
      (!practiceReview.isPprpProgramReview && decision.isComply === false && decision.followUpDate === null) ||
      (decision.assignedPdCourses.some((c) => c.isDirected) && decision.directedPdDueDate === null)
    );
  }

  function prIsCompleted(practiceReview: PracticeReview) {
    return practiceReview?.status?.baseStatusCode === PRBaseStatusCode.Completed;
  }

  const [generateDecisionLettersMutate, { loading: generatingDecisionLetters }] = useMutation<
    {
      committeeMeeting: Partial<CommitteeMeeting>;
    },
    {
      meetingId: number;
    }
  >(GenerateDecisionLettersMutation);

  async function generateDecisionLetters() {
    if (selectedMeetingId != null) {
      var result = await generateDecisionLettersMutate({
        variables: { meetingId: selectedMeetingId }
      });
      if (result.data?.committeeMeeting !== null) {
        notifications.success("Decision letters generated.");
      }
      setRegenerateLettersDialogOpen(false);
    }
  }

  async function handleGenerateLetters() {
    if (selectedMeetingId !== null) {
      if (selectedMeeting!.decisionLettersGenerated) {
        setRegenerateLettersDialogOpen(true);
      } else {
        await generateDecisionLetters();
      }
    }
  }

  const [toggleFinalReportReviewedMutate] = useMutation<
    {
      practiceReview: {
        toggleFinalReportReviewed: Partial<PracticeReview>;
        __typename?: string;
      };
    },
    {
      practiceReviewId: number;
      role: "Coordinator" | "Director";
    }
  >(ToggleFinalReportReviewedMutation);

  const [toggleTabledLetterReviewedMutate] = useMutation<
    {
      committeeMeetingTabledReview: {
        toggleTabledLetterReviewed: Partial<CommitteeMeetingTabledReview>;
        __typename?: string;
      };
    },
    {
      committeeMeetingId: number;
      practiceReviewId: number;
      role: "Coordinator" | "Director";
    }
  >(ToggleTabledLetterReviewedMutation);

  async function toggleReviewed(practiceReview: PracticeReview, role: "Coordinator" | "Director") {
    if (practiceReview.tabledToNext) {
      await toggleTabledLetterReviewedMutate({
        variables: {
          committeeMeetingId: practiceReview.tabledToNext.committeeMeetingId,
          practiceReviewId: practiceReview.tabledToNext.practiceReviewId,
          role: role
        },
        optimisticResponse: {
          committeeMeetingTabledReview: {
            toggleTabledLetterReviewed: {
              ...practiceReview.tabledToNext,
              isTabledLetterReviewedByCoordinator:
                role === "Coordinator"
                  ? !practiceReview.tabledToNext.isTabledLetterReviewedByCoordinator
                  : practiceReview.tabledToNext.isTabledLetterReviewedByCoordinator,
              isTabledLetterReviewedByDirector:
                role === "Director"
                  ? !practiceReview.tabledToNext.isTabledLetterReviewedByDirector
                  : practiceReview.tabledToNext.isTabledLetterReviewedByDirector
            },
            __typename: ""
          }
        }
      });
    } else {
      await toggleFinalReportReviewedMutate({
        variables: {
          practiceReviewId: practiceReview.id,
          role: role
        },
        optimisticResponse: {
          practiceReview: {
            toggleFinalReportReviewed: {
              ...practiceReview,
              isFinalReportReviewedByCoordinator:
                role === "Coordinator"
                  ? !practiceReview.isFinalReportReviewedByCoordinator
                  : practiceReview.isFinalReportReviewedByCoordinator,
              isFinalReportReviewedByDirector:
                role === "Director" ? !practiceReview.isFinalReportReviewedByDirector : practiceReview.isFinalReportReviewedByDirector
            },
            __typename: ""
          }
        }
      });
    }
  }

  const [releaseFinalReportsForDirectorReviewMutate, releaseFinalReportsForDirectorReviewMutation] = useMutation<
    {
      committeeMeeting: {
        releaseFinalReportsForDirectorReview: boolean;
      };
    },
    {
      meetingId: number;
    }
  >(ReleaseFinalReportsForDirectorReviewMutation);

  async function releaseForDirectorReview() {
    const result = await releaseFinalReportsForDirectorReviewMutate({
      variables: {
        meetingId: selectedMeetingId!
      },
      refetchQueries: [
        {
          query: GetMeetingsForFinalDecisionQuery,
          variables: { forCoordinator: true, registrationCommittees: props.registrationCommittees ?? false }
        }
      ]
    });

    if (result.data?.committeeMeeting.releaseFinalReportsForDirectorReview) {
      notifications.success(`Released meeting for ${props.registrationCommittees ? "registrar" : "director"} review.`);
    }
  }

  const [approveFinalReportsMutate, approveFinalReportsMutation] = useMutation<
    {
      practiceReview: {
        approveFinalReports: PracticeReview[];
      };
    },
    {
      committeeMeetingId: number;
      prIdsToApproveFinalReports: number[];
      prIdsToApproveDecisionTabledLetters: number[];
    }
  >(ApproveFinalReportsMutation);

  async function approveFinalReports(practiceReviews: PracticeReview[]) {
    const result = await approveFinalReportsMutate({
      variables: {
        committeeMeetingId: selectedMeeting!.id,
        prIdsToApproveFinalReports: practiceReviews.filter((pr) => !pr.tabledToNext).map((pr) => pr.id!),
        prIdsToApproveDecisionTabledLetters: practiceReviews.filter((pr) => pr.tabledToNext).map((pr) => pr.id!)
      },
      refetchQueries: [
        {
          query: GetMeetingsForFinalDecisionQuery,
          variables: { forCoordinator: false, registrationCommittees: props.registrationCommittees ?? false }
        }
      ]
    });
    if ((result.data?.practiceReview?.approveFinalReports?.length ?? 0) > 0) {
      notifications.success(
        `PR${practiceReviews.length === 1 ? "" : "s"} ${practiceReviews.map((pr) => pr.prNumber).join(", ")} approved.`
      );
    }
  }

  async function downloadFinalReportPackage(pr: PracticeReview) {
    setFinalReportPackageForPrIdDownloading(pr.id);

    let getResult: AxiosResponse<never>;
    const getRequestEndpoint = `${appConfig.apiEndpoint}/api/practice-review-document/final-report-package?practiceReviewId=${pr.id}`;
    try {
      getResult = await fileDownloadAxios.get(getRequestEndpoint);
      if (getResult === undefined || getResult?.status === 401) {
        getResult = await fileDownloadAxios.get(getRequestEndpoint);
      }
      if (getResult?.status === 200) {
        fileDownload(getResult.data, `Final Report Package - PR ${pr.prNumber}.pdf`);
      } else {
        notifications.serverError(new Error(getResult?.statusText));
      }

      setFinalReportPackageForPrIdDownloading(null);
    } catch (e) {
      setFinalReportPackageForPrIdDownloading(null);
      notifications.error(e);
    }
  }

  const allPrDecisionsComplete = prsForSelectedMeeting.every((pr) => !decisionIsIncomplete(pr));

  const ApproveFinalReportsWarningDialog = () => (
    <ConfirmationDialog
      open={!!finalReportsToApprove}
      title="Approve final reports and/or decision letters?"
      body="Once you approve a (non-tabled) PR, only the follow-up date can be changed on the Committee Decision. (For tabled PRs, the tabled decision letter will be sent to the firm.)"
      confirmButtonText="Continue"
      noDanger
      loading={approveFinalReportsMutation.loading}
      confirm={async () => {
        await approveFinalReports(finalReportsToApprove!);
        setFinalReportsToApprove(null);
      }}
      cancel={() => {
        setFinalReportsToApprove(null);
      }}
    />
  );

  const motionColumns: GridColumns<PracticeReview> = useMemo<GridColumns<PracticeReview>>(
    () =>
      selectedMeeting?.isRegistrationCommittee
        ? [
            {
              field: "motionPprpProgramReview",
              headerName: "PPRP Motion",
              headerClassName: classes.wrapHeader,
              width: 68,
              hide: !selectedMeeting?.isRegistrationCommittee,
              valueGetter: ({ row }) => row.mostAuthoritativeDecision?.motionPprpProgramReview?.motionCode ?? "--"
            }
          ]
        : [
            {
              field: "motionStandard",
              headerName: "Standard Motion",
              headerClassName: classes.wrapHeader,
              width: 66,
              valueGetter: ({ row }) => row.mostAuthoritativeDecision?.motionStandard?.motionCode ?? "--"
            },
            {
              field: "motionPd",
              headerName: "PD Motion",
              headerClassName: classes.wrapHeader,
              width: 60,
              hide: !prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionPd?.motionCode),
              renderCell: ({ row }) => (
                <div
                  style={{ overflow: "hidden", whiteSpace: "break-spaces" }} // so that it wraps "PD-RECDIRECT" on the dash
                  title={row.mostAuthoritativeDecision?.motionPd?.shortDescription}>
                  {row.mostAuthoritativeDecision?.motionPd?.motionCode ?? "--"}
                </div>
              )
            },
            {
              field: "motionCpab",
              headerName: "CPAB Motion",
              headerClassName: classes.wrapHeader,
              width: 54,
              hide: !prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCpab?.motionCode),
              valueGetter: ({ row }) => row.mostAuthoritativeDecision?.motionCpab?.motionCode ?? "--"
            },
            {
              field: "motionCic",
              headerName: "CIC Motion",
              headerClassName: classes.wrapHeader,
              width: 54,
              hide: !prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCic?.motionCode),
              valueGetter: ({ row }) => row.mostAuthoritativeDecision?.motionCic?.motionCode ?? "--"
            }
          ],
    [
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionPd?.motionCode),
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCpab?.motionCode),
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCic?.motionCode)
    ]
  );

  const reviewedColumns: GridColumns<PracticeReview> = useMemo<GridColumns<PracticeReview>>(
    () =>
      props.mode === "review-final-reports"
        ? [
            {
              field: "isFinalReportReviewedByDirector",
              headerName: "Reviewed",
              headerAlign: "center",
              width: 72,
              align: "center",
              hide: !selectedMeeting?.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const practiceReview = row;
                const reviewed = practiceReview.tabledToNext
                  ? practiceReview.tabledToNext.isTabledLetterReviewedByDirector
                  : practiceReview.isFinalReportReviewedByDirector;

                return (
                  <Tooltip
                    title={
                      decisionIsIncomplete(practiceReview)
                        ? "The committee decision is incomplete."
                        : prIsCompleted(practiceReview)
                        ? "The review is already completed."
                        : ""
                    }>
                    <span>
                      <Checkbox
                        checked={reviewed}
                        disabled={decisionIsIncomplete(practiceReview) || prIsCompleted(practiceReview)}
                        onClick={() => toggleReviewed(practiceReview, "Director")}
                      />
                    </span>
                  </Tooltip>
                );
              }
            }
          ]
        : [
            {
              field: "isFinalReportReviewedByCoordinator",
              headerName: "Reviewed",
              headerAlign: "center",
              width: 72,
              align: "center",
              hide: !selectedMeeting?.decisionLettersGenerated,
              renderCell: ({ row }) => {
                const practiceReview = row;
                const reviewed = practiceReview.tabledToNext
                  ? practiceReview.tabledToNext.isTabledLetterReviewedByCoordinator
                  : practiceReview.isFinalReportReviewedByCoordinator;

                return (
                  <Tooltip title={decisionIsIncomplete(practiceReview) ? "The committee decision is incomplete." : ""}>
                    <span>
                      <Checkbox
                        checked={reviewed}
                        disabled={decisionIsIncomplete(practiceReview) || practiceReview.isFinalReportReviewedByDirector}
                        onClick={() => toggleReviewed(practiceReview, "Coordinator")}
                      />
                    </span>
                  </Tooltip>
                );
              }
            }
          ],
    [props.mode, selectedMeeting?.decisionLettersGenerated]
  );
  const tableColumns: GridColumns<PracticeReview> = useMemo<GridColumns<PracticeReview>>(
    () => [
      {
        field: "committeeTab",
        headerName: "Tab",
        width: 50,
        valueGetter: ({ row }) => (row.tabledToNext ? Number(row.tabledToNext.tab) : Number(row.committeeTab))
      },
      {
        field: "prNumber",
        headerName: "PR#",
        width: 70,
        renderCell: ({ row }) => {
          const practiceReview = row;
          return (
            <Link component={RouterLink} to={getRouteForPracticeReview(practiceReview)}>
              {practiceReview.prNumber}
            </Link>
          );
        }
      },
      {
        field: "firm.name",
        headerName: "Firm Name",
        flex: 1,
        minWidth: 120,
        valueGetter: ({ row }) => row.firm?.name,
        renderCell: ({ row }) => (
          <Tooltip title={row.firm.name}>
            <span>{row.firm.name}</span>
          </Tooltip>
        )
      },
      {
        field: "tabledFromPrevious",
        headerName: "Tabled from Previous",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        cellClassName: classes.hideBooleanXsInCell,
        type: "boolean",
        width: 85,
        hide: !prsForSelectedMeeting.some((pr) => pr.tabledFromPrevious),
        renderCell: ({ row }) => (
          <Tooltip title={row.tabledFromPrevious?.reason ?? ""}>
            {row.tabledFromPrevious ? <GridCheckIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} /> : <></>}
          </Tooltip>
        )
      },
      {
        field: "assessment",
        headerName: "Assessment",
        width: 96,
        valueGetter: ({ row }) =>
          row.mostAuthoritativeDecision ? (row.mostAuthoritativeDecision!.isComply ? "Comply" : "Non-comply") : "--",
        cellClassName: (params: GridCellParams) =>
          cx({
            [classes.comply]: params.row.mostAuthoritativeDecision?.isComply === true,
            [classes.nonComply]: params.row.mostAuthoritativeDecision?.isComply === false
          })
      },
      ...motionColumns,
      {
        field: "tabledToNext",
        headerName: "Tabled To Next",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        cellClassName: classes.hideBooleanXsInCell,
        type: "boolean",
        width: 70,
        hide: !prsForSelectedMeeting.some((pr) => pr.tabledToNext),
        renderCell: ({ row }) => (
          <Tooltip title={row.tabledToNext?.reason ?? ""}>
            {row.tabledToNext ? <GridCheckIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} /> : <></>}
          </Tooltip>
        )
      },
      {
        field: "decisionLetterLink",
        headerName: "Decision Letter",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 64,
        sortable: false,
        hide: !selectedMeeting?.decisionLettersGenerated,
        renderCell: ({ row }) => {
          const practiceReview = row;
          return practiceReview.hasDecisionLetter && practiceReview.decisionLetterUrl ? (
            <MicrosoftWordLink href={practiceReview.decisionLetterUrl}>{prIsCompleted(practiceReview) ? "View" : "Edit"}</MicrosoftWordLink>
          ) : null;
        }
      },
      {
        field: "prReportLink",
        headerName: "PR Report",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 56,
        sortable: false,
        renderCell: ({ row }) => {
          const practiceReview = row;
          return practiceReview.practiceReviewReportUrl ? (
            <MicrosoftWordLink href={practiceReview.practiceReviewReportUrl}>
              {prIsCompleted(practiceReview) ? "View" : "Edit"}
            </MicrosoftWordLink>
          ) : null;
        }
      },
      {
        field: "pdDeclarationFormLink",
        headerName: "PD Declaration",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 82,
        sortable: false,
        hide:
          props.registrationCommittees ||
          !selectedMeeting?.decisionLettersGenerated ||
          !prsForSelectedMeeting.some((pr) => pr.pdDeclarationFormUrl),
        renderCell: ({ row }) => {
          const practiceReview = row;
          return practiceReview.pdDeclarationFormUrl ? (
            <MicrosoftWordLink href={practiceReview.pdDeclarationFormUrl}>Edit</MicrosoftWordLink>
          ) : null;
        }
      },
      {
        field: "pdProofOfAttendanceFormsLink",
        headerName: "PD Proof of Attendance Forms",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 82,
        sortable: false,
        hide:
          props.registrationCommittees ||
          !selectedMeeting?.decisionLettersGenerated ||
          !prsForSelectedMeeting.some((pr) => pr.pdProofOfAttendanceFormsUrl),
        renderCell: ({ row }) => {
          const practiceReview = row;
          return practiceReview.pdProofOfAttendanceFormsUrl ? <Link href={practiceReview.pdProofOfAttendanceFormsUrl}>Edit</Link> : null;
        }
      },
      {
        field: "finalReportLink",
        headerName: "Final Report Package",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 86,
        sortable: false,
        hide: !selectedMeeting?.decisionLettersGenerated,
        renderCell: ({ row }) => {
          const practiceReview = row;
          return practiceReview.tabledToNext ? null : prIsCompleted(practiceReview) && practiceReview.finalReportPackageUrl ? (
            <Link href={practiceReview.finalReportPackageUrl}>View</Link>
          ) : finalReportPackageForPrIdDownloading === practiceReview.id ? (
            <CircularProgress size="small" />
          ) : (
            <Link component="button" variant="body2" onClick={() => downloadFinalReportPackage(practiceReview)}>
              View
            </Link>
          );
        }
      },
      {
        field: "editDecisionLink",
        headerName: "Edit Decision",
        headerAlign: "center",
        headerClassName: classes.wrapHeader,
        align: "center",
        width: 62,
        sortable: false,
        renderCell: ({ row }) => {
          const practiceReview = row;
          return (
            <Tooltip
              title={
                committeeDecisionIsMissing(practiceReview)
                  ? "This PR's director decision has not been signed off yet."
                  : decisionIsIncomplete(practiceReview)
                  ? "This PR requires additional information on its committee decision before its decision letter can be generated."
                  : ""
              }>
              <span>
                <IconButton
                  color={decisionIsIncomplete(practiceReview) ? "error" : "primary"}
                  size="small"
                  disabled={committeeDecisionIsMissing(practiceReview)}
                  onClick={() =>
                    history.push(
                      getRouteForPracticeReview(practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Committee)
                    )
                  }>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
              </span>
            </Tooltip>
          );
        }
      },
      ...reviewedColumns,
      {
        field: "Approve",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 120,
        sortable: false,
        hide: !selectedMeeting?.decisionLettersGenerated || props.mode === "enter-committee-decisions",
        renderCell: ({ row }) => {
          const practiceReview = row;

          if (practiceReview.tabledToNext?.decisionTabledLetterHasBeenSent) {
            return <Typography>Letter Sent</Typography>;
          } else
            return prIsCompleted(practiceReview) ? (
              <Typography>Completed</Typography>
            ) : (
              <Tooltip
                title={
                  (
                    practiceReview.tabledToNext
                      ? !practiceReview.tabledToNext.isTabledLetterReviewedByDirector
                      : !practiceReview.isFinalReportReviewedByDirector
                  )
                    ? "The PR must be marked as reviewed."
                    : ""
                }>
                <span>
                  <Button
                    variant="outlined"
                    disabled={
                      practiceReview.tabledToNext
                        ? !practiceReview.tabledToNext.isTabledLetterReviewedByDirector
                        : !practiceReview.isFinalReportReviewedByDirector
                    }
                    onClick={() => {
                      setFinalReportsToApprove([practiceReview]);
                    }}>
                    Approve
                  </Button>
                </span>
              </Tooltip>
            );
        }
      }
    ],
    [
      props.mode,
      selectedMeeting?.decisionLettersGenerated,
      prsForSelectedMeeting.some((pr) => pr.tabledToNext),
      prsForSelectedMeeting.some((pr) => pr.tabledFromPrevious),
      prsForSelectedMeeting.some((pr) => pr.pdDeclarationFormUrl),
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionPd?.motionCode),
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCpab?.motionCode),
      prsForSelectedMeeting.some((pr) => pr.mostAuthoritativeDecision?.motionCic?.motionCode)
    ]
  );

  const unreviewedReviewsExist =
    orderedMeetingPrs.some((pr) => !pr.isFinalReportReviewedByCoordinator) ||
    orderedTabledPrs.some((pr) => !pr.tabledToNext?.isTabledLetterReviewedByCoordinator);
  const unreviewedItemsExist = itemsForSelectedMeeting.some((item) => !item.isFinalReportReadyForReview);
  const disableReleaseReason =
    unreviewedReviewsExist || unreviewedItemsExist
      ? `All ${[...(unreviewedReviewsExist ? ["PRs"] : []), ...(unreviewedItemsExist ? ["items"] : [])].join(
          " and "
        )} must be marked as reviewed.`
      : "";
  return (
    <CollapsibleProvider screen="CommitteeDecisions">
      <Helmet>
        <title>{`${props.mode === "enter-committee-decisions" ? "Enter Committee Decisions" : "Review Final Reports"} - PRS Online`}</title>
      </Helmet>

      <ApproveFinalReportsWarningDialog />

      <Stack sx={{ minHeight: "100%" }}>
        <ScreenHeader title={props.mode === "enter-committee-decisions" ? "Enter Committee Decisions" : "Review Final Reports"} />

        <Paper sx={{ p: 3, flexGrow: 1 }}>
          {meetings.length === 0 && !meetingsQuery.loading ? (
            <Typography>{`There are currently no meetings requiring committee decisions to be ${
              props.mode === "enter-committee-decisions" ? "entered" : "reviewed"
            }.`}</Typography>
          ) : (
            <Stack spacing={3}>
              <Stack direction="row" spacing={5} sx={{ justifyContent: "space-between" }}>
                <FormControl size="small" sx={{ width: "30em" }}>
                  <InputLabel id="meeting-select-label">Committee Meeting</InputLabel>
                  <Select
                    labelId="meeting-select-label"
                    value={selectedMeetingId ?? ""}
                    label="Committee Meeting"
                    onChange={(e) => setSelectedMeetingId(Number(e.target.value))}>
                    {meetings.map((m) => (
                      <MenuItem key={m.id} value={m.id}>
                        {m.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Stack direction="row" spacing={2} alignItems="center">
                  {props.mode === "enter-committee-decisions" && (
                    <>
                      <Tooltip
                        title={
                          !selectedMeeting
                            ? "No meeting is selected."
                            : prsForSelectedMeeting.some((pr) => decisionIsIncomplete(pr))
                            ? "Some PRs in the meeting have incomplete committee decisions."
                            : ""
                        }>
                        <span>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setEnteringRegrets(true)}
                            disabled={!selectedMeeting || prsForSelectedMeeting.some((pr) => decisionIsIncomplete(pr))}>
                            {selectedMeeting?.minutesGenerated ? "Regenerate" : "Generate"} Minutes
                          </Button>
                        </span>
                      </Tooltip>
                      {selectedMeeting?.minutesUrl && <MicrosoftWordLink href={selectedMeeting.minutesUrl}>Open Minutes</MicrosoftWordLink>}
                      <Tooltip title={!allPrDecisionsComplete ? "Some PRs have incomplete committee decisions." : ""}>
                        <span>
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            loading={generatingDecisionLetters}
                            onClick={handleGenerateLetters}
                            disabled={!allPrDecisionsComplete}>
                            {selectedMeeting?.decisionLettersGenerated ? "Regenerate" : "Generate"} Decision Letters
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Stack>

              {hasItemsInMeeting && (
                <RegistrationCommitteeDecisionsTable
                  itemsForSelectedMeeting={itemsForSelectedMeeting}
                  mode={props.mode}
                  selectedMeetingId={selectedMeetingId!}
                  decisionLettersGenerated={selectedMeeting!.decisionLettersGenerated}
                />
              )}
              {selectedMeetingId && prsForSelectedMeeting.length > 0 && (
                <Stack spacing={2}>
                  <div style={{ width: "100%" }}>
                    <DataGridWithHeader
                      title={
                        hasItemsInMeeting ? (
                          <Typography variant="h2" style={{ fontWeight: 300 }}>
                            PPRP Program Reviews
                          </Typography>
                        ) : undefined
                      }
                      className={cx([classes.datagridRoot, classes.highDensityDataGridRoot])}
                      deemphasizeHeader
                      displayWithoutContainer
                      allowHidingColumns
                      disableSelectionOnClick
                      loading={meetingWithFilesQuery.loading}
                      rows={prsForSelectedMeeting}
                      columns={tableColumns}
                      minHeight={sectionMinHeight}
                      pinnedColumns={{ left: ["committeeTab", "prNumber"] }}
                    />
                  </div>
                </Stack>
              )}
              <Stack direction="row" spacing={2} sx={{ alignSelf: "flex-end" }}>
                {selectedMeeting?.decisionLettersGenerated && props.mode === "enter-committee-decisions" && (
                  <Tooltip title={disableReleaseReason}>
                    <span>
                      <LoadingButton
                        color="primary"
                        variant="contained"
                        onClick={() => releaseForDirectorReview()}
                        loading={releaseFinalReportsForDirectorReviewMutation.loading}
                        disabled={disableReleaseReason !== ""}>
                        Release for {props.registrationCommittees ? "Registrar" : "Director"} Review
                      </LoadingButton>
                    </span>
                  </Tooltip>
                )}

                {selectedMeeting?.decisionLettersGenerated && props.mode === "review-final-reports" && prsReadyForFinalApproval.length > 0 && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={meetingsQuery.loading || meetingWithFilesQuery.loading}
                      onClick={() => {
                        setFinalReportsToApprove(prsReadyForFinalApproval);
                      }}>
                      Approve All Reviewed
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Paper>
      </Stack>

      {enteringRegrets && selectedMeetingId && (
        <CommitteeMeetingRegretsDialog
          committeeMeetingId={selectedMeetingId}
          isRegistrationCommittee={selectedMeeting?.isRegistrationCommittee ?? false}
          isRegenerate={selectedMeeting?.minutesGenerated}
          onClose={() => setEnteringRegrets(false)}
        />
      )}

      <ConfirmationDialog
        open={regenerateLettersDialogOpen}
        title="Regenerate decision letters?"
        loading={generatingDecisionLetters}
        body={
          <>
            <DialogContentText>Do you want to regenerate decision letters for {selectedMeeting?.name}?</DialogContentText>
            <DialogContentText>
              <strong>This will regenerate the documents in the final report package, discarding all changes made to them.</strong>
            </DialogContentText>
          </>
        }
        confirm={generateDecisionLetters}
        cancel={() => setRegenerateLettersDialogOpen(false)}
      />
    </CollapsibleProvider>
  );
};

export default CommitteeDecisionsScreen;
